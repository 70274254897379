// const COLORS = ["error"]
const ICONS = {
    // info: "mdi-information",
    // warning: "mdi-alert",
    error: "mdi-alert-circle",
    // success: "mdi-check-circle",
}
export const items = {
    namespaced: true,
    state: {
		started: false,
		items: [
            {
                id: 1,
                color: "info",
                icon: ICONS.info,
				title: 'Initiierung',
				text: 'Bitte bewahre Ruhe, die Woche wird in wenigen Augenblicken bis Freitagabend vorgespult!'
            },
        ],
    },
    getters: {
        getItems(state) {
            return state.items
        },
        isStarted(state) {
            return state.started
        },
    },
    actions: {
        addItem(context, payload) {
            context.commit('addItem', payload)
        },
        setStarted(context, payload) {
            context.commit('setStarted', payload)
        },
    },
    mutations: {
        addItem(state, item) {
            state.items.unshift(item)
        },
        setStarted(state, started) {
            state.started = started
        },
    }
}
