export const messages = {
    namespaced: true,
    state: {
        id: '',
		days: {
			0: {key: 'monday', title: 'Montag'},
			1: {key: 'tuesday', title: 'Dienstag'},
			2: {key: 'wednesday', title: 'Mittwoch'},
			3: {key: 'thursday', title: 'Donnerstag'},
			4: {key: 'friday', title: 'Freitag'},
			5: {key: 'saturday', title: 'Samstag'},
			6: {key: 'sunday', title: 'Sonntag'},
		},
		day: (new Date().getDay() !== 0 ? new Date().getDay() : 7),
		used: {},
		messages: [
			'Platziere überall Einhornponys.',
			'Kaffeemaschinen werden gereinigt und befüllt.',
            // 'Zahle fünf Euro an Sio K. Lypse (<a href="https://www.paypal.com/paypalme/sioweb/5" target="_blank" title="Spendenlink für Sio K. Lypse">Paypal</a>)',
			'Getränkekühlschränke werden aufgefüllt.',
			'Sonnenstunden werden erhöht.',
            'Liegestühle werden aufgeklappt.',
            'Gratiseis für alle!',
            'Happy Hour von 00:00 Uhr bis 24:00 Uhr!',
            'Lamas werden gebürstet.',
            'Lade alle Akkus voll auf.',
            'Papierkorb wird geleert.',
            'Gute Netflixserien werden gesucht und empfohlen.',
            'Komplette Wohnung wird gesaugt.',
            'Lieferstatus wird auf "In Zustellung" gestellt.',
            'Chipsvorräte werden aufgestockt.',
		]
    },
    getters: {
        getMessages(state) {
            return state.messages
        },
        getDay: (state) => (index) => {
            return state.days[index]
        },
        getDayIndex(state) {
            return state.day
        },
        getDays(state) {
            return state.days
        },
        getUsed(state) {
            return state.used
        }
    },
    actions: {
        addMessage(context, payload) {
            context.commit('addMessage', payload)
        },
        setDay(context, payload) {
            context.commit('setDay', payload)
        }
    },
    mutations: {
        addMessage(state, index) {
            state.used[index] = state.messages[index]
        },
        setDay(state, day) {
            state.day = day
        }
    }
}
