<template>
  <v-app>
    <v-main>
      <v-progress-linear
          :active="showProgress()"
          :indeterminate="showProgress()"
          height="7"
          absolute
          top
          reverse
          color="green darken-3"
      ></v-progress-linear>

      <v-fade-transition>
        <Back2Future v-if="showButton()"/>
      </v-fade-transition>
      <v-fade-transition>
        <Timeline v-if="showTimeline()" />
      </v-fade-transition>
      
      <div id="nav">
        <span>§</span>
        <div id="imprint" @click="content='imprint'">Impressum</div>
      </div>

      <div class="links">
        <a class="twitter" href="https://twitter.com/Siokalypse" title="Direkt zum Profil von Sio K. Lypse">@Siokalypse</a>
        |
        <a class="twitter-share" target="_blank" href="https://twitter.com/intent/tweet?text=Auf zum Wochende%0a%0aDie @Siokalypse kann noch etwas warten!%0ahttps://zum-wochenende-springen.de" title="Deine Untat auf Twitter teilen.">Auf Twitter teilen</a>
        |
        <a href="https://www.paypal.com/paypalme/sioweb/2.50" target="_blank" title="Spendenlink für Sio K. Lypse">Kaffee für Sio</a>
      </div>

      <div v-click-outside="clearContent" v-if="content === 'imprint'" id="content-imprint">
        <span id="close-content-imprint" @click="content = ''">x</span>
        <h3>Anschrift</h3>
        <p>Sascha Weidner<br>Siokalypse<br>Am Sonnenrain 14<br>79761 Waldshut-Tiengen</p>
        <h3>Kontakt:</h3>
        <p>Telefon +49(0)1746092908<br><a href="mailto:info@zurück-zu-montag.de">info@zurück-zu-montag.de</a></p>
      </div>
    </v-main>
  </v-app>
</template>

<style lang="scss">
  @import "./assets/scss/main.scss";
</style>

<script>
import {mapGetters} from 'vuex'
export default {
  name: 'App',

  data: () => ({
    content: ''
    //
  }),
  methods: {
    clearContent() {
      this.content = ''
    },
    showButton() {
      return this.getLevel !== 2
    },
    showTimeline() {
      return this.getLevel === 2
    },
    showProgress() {
      return !this.isFinished && (this.getLevel === 1 || this.getLevel === 2)
    }
  },

  computed: {
      ...mapGetters('weekend', ['getLevel', 'isFinished']),
  }
};
</script>
