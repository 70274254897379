<template>
    <div>
        <div
            v-if="isFinished"
            @click="setLevel(3)"
            class="back-to-home"
        >
            Zurück zur Übersicht
        </div>
        <div class="timeline-wrap" >
            <v-timeline dense>
                <v-slide-x-reverse-transition group hide-on-leave>
                    <v-timeline-item
                        v-for="item in getItems"
                        :key="item.id"
                        :color="'success'"
                        :text="item.text"
                        small
                        fill-dot
                    >
                        <v-card class="elevation-2">
                            <v-card-title
                                v-if="item.title"
                                class="headline"
                                v-html="item.title"
                            />
                            <v-card-text v-html="item.text" />
                        </v-card>
                    </v-timeline-item>
                </v-slide-x-reverse-transition>
            </v-timeline>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// const COLORS = ["info", "warning", "error", "success"]
const COLORS = ["success"];
const ICONS = {
    info: "mdi-information",
    // warning: "mdi-alert",
    error: "mdi-alert-circle",
    success: "mdi-check-circle",
};

export default {
    data: () => ({
        interval: null,
        nonce: 2,
    }),

    mounted() {
        if (!this.isFinished) {
            this.interval = setInterval(this.addEvent, 3000);
            // this.interval = setInterval(this.addEvent, 300);
        }
    },

    beforeDestroy() {
        this.stop();
    },

    methods: {
        addEvent() {
            let { color, icon } = this.genAlert();

            const previousColor = this.getItems[0].color;

            while (previousColor === color) {
                color = this.genColor();
            }

            if(!this.isStarted) {
                this.getItems.pop();
            }

            this.addItem({
                id: this.nonce++,
                color,
                icon,
                text: this.genText(),
                title: this.getDay((this.getDayIndex - 1) + this.getItems.length).title,
            });

            this.setStarted(true)

            if (this.getItems.length === 5 - (this.getDayIndex - 1)) {
                this.stop();
                setTimeout(() => {
                    this.finish();
                }, 3000);
            }
        },
        genAlert() {
            const color = this.genColor();

            return {
                color,
                icon: this.genIcon(color),
            };
        },
        genColor() {
            return COLORS[Math.floor(Math.random() * 3)];
        },
        genIcon(color) {
            return ICONS[color];
        },
        genText() {
            let random = Math.floor(Math.random() * this.getMessages.length),
                saveLoop = 0,
                // countTexts = 1 + Math.floor(Math.random()*2),
                loopCount = 0,
                text;

            text = "<ul>";
            while (loopCount < 2) {
                while (this.getUsed[random] !== undefined) {
                    random = Math.floor(
                        Math.random() * this.getMessages.length
                    );
                    saveLoop++;

                    if (saveLoop > 50) {
                        this.stop();
                        this.setFinished(true);
                        break;
                    }
                }
                this.addMessage(random);
                text += "<li>" + this.getMessages[random] + "</li>";
                loopCount++;
            }

            text += "</ul>";

            return text;
        },
        stop() {
            clearInterval(this.interval);
            this.interval = null;
        },
        finish() {
            this.setLevel(3);
            this.setFinished(true);
            this.setEmoji("happy");
        },
        ...mapActions("weekend", ["setLevel", "setEmoji", "setFinished"]),
        ...mapActions("messages", ["addMessage"]),
        ...mapActions("items", ["addItem", "setStarted"]),
    },

    computed: {
        ...mapGetters("weekend", ["getLevel", "getEmoji", "isFinished"]),
        ...mapGetters("messages", [
            "getDay",
            "getMessages",
            "getUsed",
            "getDayIndex",
        ]),
        ...mapGetters("items", ["getItems", "isStarted"]),
    },
};
</script>