import Vue from 'vue';
import Vuex from 'vuex';

import { items } from './items.module';
import { weekend } from './weekend.module';
import { messages } from './messages.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        items,
        weekend,
        messages,
    },
});
