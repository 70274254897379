export const weekend = {
    namespaced: true,
    state: {
        id: '',
        level: 0,
        finished: false,
        emoji: 21,
        emojis: {
            neutral: '21',
            attention: '42', // hm?
            warning: '31',
            danger: '30',
            love: '54',
            cry: '37', // Kuss
            happy: '56',
        }
    },
    getters: {
        isFinished(state) {
            return state.finished
        },
        getLevel(state) {
            return state.level
        },
        getEmojiIndex(state) {
            return state.emoji
        },
        getEmojis(state) {
            return state.emojis
        }
    },
    actions: {
        setLevel(context, payload) {
            context.commit('setLevel', payload)
        },
        setFinished(context, payload) {
            context.commit('setFinished', payload)
        },
        setEmoji(context, payload) {
            context.commit('setEmoji', payload)
        }
    },
    mutations: {
        setLevel(state, level) {
            state.level = level
        },
        setFinished(state, finished) {
            state.finished = finished
        },
        setEmoji(state, emoji) {
            // if(state.emoji !== state.emojis.devil && state.emoji !== state.emojis.cry) {
                state.emoji = state.emojis[emoji];
            // }
        }
    }
}
