import Vue from 'vue'
import Vuex from 'vuex'
import { store } from './_store'
import App from './App.vue'
import vuetify from './plugins/vuetify';

Vue.use(Vuex)

import Back2Future from './components/Back2Future';
import Timeline from './components/Timeline';

Vue.config.productionTip = false

Vue.component('Back2Future', Back2Future)
Vue.component('Timeline', Timeline)

new Vue({
  store,
  vuetify,
  components: {
    Back2Future,
    Timeline,
  },
  render: h => h(App)
}).$mount('#app')
