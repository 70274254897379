<template>
    <div
        @mouseover.stop="updateEmoji('neutral')"
        class="button-areas area-neutral"
    >
        <div
            @mouseover.stop="updateEmoji('attention')"
            class="button-areas area-attention"
        >
            <div
                @mouseover.stop="updateEmoji('warning')"
                class="button-areas area-warning"
            >
                <div
                    @mouseover.stop="updateEmoji('danger')"
                    class="button-areas area-danger"
                >

                    <v-fade-transition>
                        <img v-if="isHappy < 2" class="emoji" :src="getEmoji">
                    </v-fade-transition>

                    <v-row align="center" justify="center">
                        <v-fade-transition>
                            <div v-if="getLevel === 0" class="text-center">
                                <button
                                    @click="clicked"
                                    @mouseover.stop="updateEmoji('love')"
                                    class="i-love-everything"
                                >
                                    Zum Wochenende!
                                </button>
                            </div>
                        </v-fade-transition>
                    </v-row>

                    <v-fade-transition>
                        <div class="we-love-you_ryl" v-if="getEmojiIndex === this.getEmojis.happy">
                            Glückwunsch, die Woche wurde erfolgreich bis Freitagabend vorgespult!<br>
                            <a class="twitter-share" target="_blank" href="https://twitter.com/intent/tweet?text=Lasst das Wochenende beginnen!%0a%0aFreigetränke für alle!%0a%0ahttps://zum-wochenende-springen.de&hashtags=Jump2Weekend" title="!">@Wunder verkünden</a>
                            <div
                                @click="setLevel(2)"
                                class="show-panel-of-love"
                            >Verlauf anzeigen</div>
                        </div>
                    </v-fade-transition>

                    <div v-if="false">
                        <button class="i-love-everything">Zum Wochenende!</button>
                        <div v-if="false" class="beam"></div>

                        <div v-if="false" class="hoop">
                            <a href="#" class="hoop-circles">
                                <div class="hoop-circle hoop-circle--1"></div>
                                <div class="hoop-circle hoop-circle--2"></div>
                                <div class="hoop-circle hoop-circle--3"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
    data: () => ({
        happy: false
    }),

    methods: {
        clicked() {
            this.setEmoji('cry')
            this.setLevel(1)
            this.happy = 1
            setTimeout(() => {
                this.happy = 2
                this.setLevel(2)
            }, 1500)
        },
        updateEmoji(name) {
            if(!this.isHappy && this.getLevel < 2) {
                this.setEmoji(name)
            }
        },
        ...mapActions('weekend', ['setLevel', 'setEmoji'])
    },

    computed: {
        getEmoji() {
            return require('../assets/svg/emoji_' + this.getEmojiIndex + '.svg')
        },
        isHappy() {
            return this.happy
        },
        ...mapGetters('weekend', ['getLevel', 'getEmojiIndex', 'getEmojis'])
    },
};
</script>